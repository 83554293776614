@keyframes patternFloat {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-pattern {
  animation: patternFloat 20s ease-in-out infinite;
}
