body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  width: 100%;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

#root {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Outfit:wght@400;500;600&display=swap');

/* Section scroll margins */
#about, #contact, #memberships {
  scroll-margin-top: 8vh;
}

#solutions {
  scroll-margin-top: 25vh;
}

@media (min-width: 768px) {
  #about, #contact, #memberships {
    scroll-margin-top: 8vh;
  }

  #solutions {
    scroll-margin-top: 30vh;
  }
}

@media (min-width: 768px) {
  #about, #contact, #memberships {
    scroll-margin-top: 8vh;
  }
}

.logo-size {
  height: 10vh;
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  image-rendering: optimizeQuality;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateZ(0);
  backface-visibility: hidden;
}

@media (min-width: 768px) {
  .logo-size {
    height: 11vh;
  }
}

@media (min-width: 1024px) {
  .logo-size {
    height: 12vh;
  }
}

/* Mobile-only orientation styles */
@media (max-width: 767px) and (orientation: landscape) {
  .landscape\:text-\[4vh\] {
    font-size: 4vh;
  }
}
