/* Modal Animation Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

.modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  transition: background-color 0.3s ease;
}

.modal.open {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 90vw;
  height: 80vh;
  max-height: 80vh;
  background-color: white;
  z-index: 70;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: scale(0.95) translateY(10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.modal.open .modal-content {
  opacity: 1;
  transform: scale(1) translateY(0);
}

@media (min-width: 768px) {
  .modal-content {
    width: 90vw;
  }
}

/* Thumbnail carousel styles */
.thumbnail-carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  -webkit-overflow-scrolling: touch;
  gap: 2rem;
  align-items: center;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  contain: size layout;
  transition: justify-content 0.3s ease-in-out;
}

.thumbnail-carousel::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

/* Base thumbnail styles */
.thumbnail-item {
  flex: 0 0 auto;
  height: 100%;
  width: auto;
  aspect-ratio: 1;
  position: relative;
  scroll-snap-align: center;
}

.thumbnail-item > div {
  width: 100%;
  height: 100%;
  position: relative;
}

/* Video title styles */
.thumbnail-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Mobile styles - portrait */
@media (max-width: 767px) and (orientation: portrait) {
  .thumbnail-title {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    opacity: 1;
    font-size: 0.875rem;
    background: linear-gradient(to bottom, 
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.7) 100%
    );
    -webkit-line-clamp: 3;
  }
}

/* Mobile styles - landscape */
@media (max-width: 767px) and (orientation: landscape) {
  .thumbnail-title {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    opacity: 1;
    font-size: 0.75rem;
    line-height: 1.2;
    background: linear-gradient(to bottom, 
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.7) 100%
    );
    -webkit-line-clamp: 2;
  }
}

/* Desktop hover styles */
@media (min-width: 768px) {
  .thumbnail-item:hover .thumbnail-title {
    opacity: 1;
  }
}

/* Portrait mode specific styles */
@media (orientation: portrait) {
  .thumbnail-carousel {
    gap: 2rem;
    padding: 1rem;
  }
}

/* Mobile landscape mode specific styles */
@media (orientation: landscape) and (max-height: 600px) {
  .thumbnail-carousel {
    gap: 0.75rem;
    padding: 0.5rem;
  }
}

/* Larger landscape screens */
@media (orientation: landscape) and (min-height: 601px) {
  .thumbnail-carousel {
    gap: 2rem;
    padding: 1rem;
  }
}

/* Center thumbnails when less than 4 items */
.thumbnail-carousel:has(> :last-child:nth-child(-n+4)) {
  justify-content: center;
}

/* Carousel container styles */
.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0;
  box-sizing: border-box;
  overflow: hidden;
}

/* Carousel navigation container */
.carousel-nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  z-index: 1;
  height: 100%;
  background: linear-gradient(90deg, 
    rgba(255,255,255,0.9) 0%,
    rgba(255,255,255,0.9) 50%,
    rgba(255,255,255,0) 100%
  );
}

.carousel-nav-container:last-child {
  background: linear-gradient(-90deg, 
    rgba(255,255,255,0.9) 0%,
    rgba(255,255,255,0.9) 50%,
    rgba(255,255,255,0) 100%
  );
}

/* Carousel navigation buttons */
.carousel-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
}

.carousel-nav svg {
  width: 1.75rem;
  height: 1.75rem;
  color: #4b5563;
  transition: color 0.2s ease-in-out;
}

.carousel-nav:hover svg {
  color: #2A3A55;
}

/* Video modal styles */
.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 80;
}

.video-modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

/* Hide native scrollbar */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Custom scrollbar track and thumb */
.modal-content {
  overflow: hidden;
}

/* Add padding to content area to account for scrollbar */
.modal-content .max-w-6xl {
  padding-right: 12px; /* width of scrollbar + 4px gap */
}

/* Custom Scrollbar Styles */
.scrollbar-always-visible {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent; /* Firefox */
  -webkit-appearance: none; /* Chrome on macOS */
}

/* Show scrollbar on macOS */
.scrollbar-always-visible::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.scrollbar-always-visible::-webkit-scrollbar-track {
  background: rgba(243, 244, 246, 0.1);
  border-radius: 4px;
}

.scrollbar-always-visible::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 4px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.scrollbar-always-visible::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}

/* Force scrollbar to always show in WebKit browsers */
.scrollbar-always-visible {
  overflow-y: scroll !important;
  /* Override macOS Chrome behavior */
  &::-webkit-scrollbar {
    display: block !important;
  }
}
