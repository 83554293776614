.titleTransition {
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.fadeOut {
  opacity: 0;
  transform: translateY(-10px);
}

.fadeIn {
  opacity: 1;
  transform: translateY(0);
}
